import React, { useState } from 'react'
import clsx from 'clsx'
import moment from 'moment'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import BackgroundImage from 'gatsby-background-image-es5'
import { motion } from 'framer-motion'
import ResolvingLink from '../ResolvingLink'
import RichText from 'prismic-reactjs/src/Component'
import MonthAgendaView from '../Calendar/MonthAgendaView'

const localizer = momentLocalizer(moment)

const MyCustomHeader = ({ label }) => {
  return (
    <span className="text-xs md:text-base text-yellow addington">{label}</span>
  )
}

const CustomEvent = ({ event: { title, start } }) => {
  const momentStart = moment(start)
  return (
    <span>
      {momentStart.format('h:mma')} {title}
    </span>
  )
}

const CustomToolbar = ({ label, onNavigate }) => {
  return (
    <div className="flex items-center text-white justify-between">
      <h1 className="text-2xl md:text-5xl">{label}</h1>
      <div className="flex text-6xl space-x-8">
        <button
          className="focus:outline-none"
          onClick={() => onNavigate('PREV')}
        >
          &lt;
        </button>
        <button
          className="focus:outline-none"
          onClick={() => onNavigate('NEXT')}
        >
          &gt;
        </button>
      </div>
    </div>
  )
}

const EventModal = ({ className, eventInfo, onClose }) => {
  const {
    title,
    formattedDateString,
    ctaLink,
    ctaText,
    description,
  } = eventInfo

  return (
    <div
      className={clsx(
        'modal z-10 fixed w-full h-full top-0 left-0 items-center justify-center',
        className
      )}
    >
      <div className="modal-overlay absolute w-full h-full bg-gray-500 opacity-75" />
      <div className="relative py-4 px-4 h-full w-full z-50">
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          className="modal-container bg-white h-full border-4 border-gray-700 shadow-lg overflow-y-auto overscroll-contain md:max-w-6xl md:mx-auto"
        >
          <div className="modal-content py-6 text-left px-6">
            <div className="flex flex-col">
              <button
                onClick={onClose}
                className="btn-small btn-yellow-outline self-end"
              >
                &#215;
              </button>
              {title && (
                <h1 className="mt-4 text-center text-gray-title">{title}</h1>
              )}
              <hr className="yellow-line" />
              <div className="flex flex-col text-gray-body mt-4">
                {formattedDateString && (
                  <h2 className="self-center text-base md:text-xl">
                    {formattedDateString}
                  </h2>
                )}
                {description && (
                  <div className="description-rich rich-gray leading-relaxed mt-8">
                    <RichText render={description} />
                  </div>
                )}
                {ctaText && ctaLink != null && (
                  <ResolvingLink
                    link={ctaLink}
                    className="mt-12 self-center btn btn-yellow-outline"
                  >
                    {ctaText}
                  </ResolvingLink>
                )}
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  )
}

export default function CalendarSection({
  events = [],
  backgroundFluidImg,
  className,
}) {
  const [currOpenedEvent, setCurrOpenedEvent] = useState(null)

  const calendar = (
    <>
      <div className="hidden md:flex h-full">
        <Calendar
          className="container mx-auto"
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          defaultView="month"
          formats={{
            weekdayFormat: (date, culture, localizer) => {
              const dayOfWeek = localizer.format(date, 'dd', culture)
              return dayOfWeek[0]
            },
            dateFormat: (date, culture, localizer) => {
              return localizer.format(date, 'D', culture)
            },
          }}
          popup
          onSelectEvent={clickedEventInfo => {
            setCurrOpenedEvent(clickedEventInfo)
          }}
          components={{
            toolbar: CustomToolbar,
            event: CustomEvent,
            month: { dateHeader: MyCustomHeader },
          }}
        />
      </div>
      <div className="flex md:hidden bg-black bg-opacity-75 h-full pb-4 px-4">
        <Calendar
          className="container mx-auto"
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          defaultView="agenda"
          views={{ agenda: MonthAgendaView }}
          formats={{
            weekdayFormat: (date, culture, localizer) => {
              const dayOfWeek = localizer.format(date, 'dd', culture)
              return dayOfWeek[0]
            },
            dateFormat: (date, culture, localizer) => {
              return localizer.format(date, 'D', culture)
            },
          }}
          popup
          onSelectEvent={clickedEventInfo => {
            setCurrOpenedEvent(clickedEventInfo)
          }}
          components={{
            toolbar: CustomToolbar,
            month: { dateHeader: MyCustomHeader },
          }}
        />
      </div>
    </>
  )

  const fluidImgStack = [
    'linear-gradient(rgba(0, 0, 0, 0.3) 100%, rgba(0, 0, 0, 0.3) 100%)',
    backgroundFluidImg,
  ]

  const wrappedCalendar =
    backgroundFluidImg == null ? (
      <div className={className}>{calendar}</div>
    ) : (
      <BackgroundImage
        Tag="section"
        className={className}

        fluid={fluidImgStack}
      >
        {calendar}
      </BackgroundImage>
    )

  return (
    <>
      {wrappedCalendar}
      {currOpenedEvent != null && (
        <EventModal
          eventInfo={currOpenedEvent}
          onClose={() => {
            setCurrOpenedEvent(null)
          }}
        />
      )}
    </>
  )
}
