import React from 'react'
import _ from 'lodash'
import { graphql } from 'gatsby'
import PageLayout from '../components/PageLayout'
import SEO from '../components/seo'
import getFluidImg from '../utils/getFluidImg'
import CalendarSection from '../components/sections/CalendarSection'
import moment from 'moment'
import { css } from '@emotion/react'

export const query = graphql`
  query CalendarPageQuery($uid: String, $lang: String) {
    prismicCalendarPage(uid: { eq: $uid }, lang: { eq: $lang }) {
      id
      uid
      data {
        page_title {
          text
        }
        page_description {
          text
        }
        calendar_background_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
    allPrismicEvent(filter: { lang: { eq: $lang } }) {
      nodes {
        data {
          end_date_and_time
          start_date_and_time
          title {
            text
          }
          description {
            text
            raw
          }
          cta_text {
            text
          }
          cta_link {
            url
            target
            link_type
          }
        }
      }
    }
    prismicNavigationBar(lang: { eq: $lang }) {
      ...NavigationBarFragment
    }
    prismicLeftNavigationMenu(lang: { eq: $lang }) {
      ...LeftNavigationMenuFragment
    }
    prismicRightNavigationMenu(lang: { eq: $lang }) {
      ...RightNavigationMenuFragment
    }
    prismicStayConnectedSection(lang: { eq: $lang }) {
      ...StayConnectedSectionFragment
    }
    prismicBottomNavigationBar(lang: { eq: $lang }) {
      ...BottomNavigationBarFragment
    }
    prismicCookieBanner(lang: { eq: $lang }) {
      ...CookieBannerFragment
    }
  }
`

const convertPrismicEventsToEvents = events => {
  if (events == null) {
    return []
  }

  const mappedEvents = events.map(event => {
    const title = _.get(event, 'data.title.text')
    const description = _.get(event, 'data.description.raw')
    const ctaText = _.get(event, 'data.cta_text.text')
    const ctaLink = _.get(event, 'data.cta_link')
    const startDate = _.get(event, 'data.start_date_and_time')
    const endDate = _.get(event, 'data.end_date_and_time')

    const momentStart = moment(startDate)
    const momentEnd = moment(endDate)

    if (
      !title ||
      !momentStart.isValid() ||
      !momentEnd.isValid() ||
      momentStart.isAfter(momentEnd)
    ) {
      return null
    }

    const startFormatted = momentStart.format('MMMM D, YYYY')
    const endFormatted = momentEnd.format('MMMM D, YYYY')
    const startTime = momentStart.format('h:mma')
    const endTime = momentEnd.format('h:mma')

    let formattedDateString

    if (startFormatted === endFormatted) {
      // They are the same day, show time before -> time after
      formattedDateString = `${startFormatted} ${startTime} - ${endTime}`
    } else {
      // Different days, gotta show both days.
      formattedDateString = `${startFormatted} ${startTime} - ${endFormatted} - ${endTime}`
    }

    const mappedEvent = {
      title,
      start: momentStart.toDate(),
      end: momentEnd.toDate(),
      formattedDateString,
    }

    if (description != null && description.length !== 0) {
      mappedEvent.description = description
    }

    if (ctaText && ctaLink != null) {
      mappedEvent.ctaText = ctaText
      mappedEvent.ctaLink = ctaLink
    }

    return mappedEvent
  })

  return _.compact(mappedEvents)
}

export default function CalendarPage({ data }) {
  const {
    prismicCalendarPage: singlePage,
    allPrismicEvent: prismicEvents,
  } = data

  if (singlePage == null || singlePage.data == null) {
    // TODO - Generate 404
    return null
  }

  const pageTitle = _.get(singlePage, 'data.page_title.text')
  const pageDescription = _.get(singlePage, 'data.page_description.text')
  const calendarBackgroundFluidImage = getFluidImg(
    _.get(singlePage, 'data.calendar_background_image')
  )

  return (
    <PageLayout pageData={data}>
      <SEO title={pageTitle} description={pageDescription} />
      <CalendarSection
        className="pb-8 pt-calendar calendar-height px-2 md:h-screen"
        backgroundFluidImg={calendarBackgroundFluidImage}
        events={convertPrismicEventsToEvents(prismicEvents?.nodes)}
      />
    </PageLayout>
  )
}
