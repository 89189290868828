import PropTypes from 'prop-types'
import React from 'react'
import { inRange } from './utils/eventLevels'
import * as dates from './utils/dates'
import moment from 'moment'

function MonthAgendaView({
  accessors,
  localizer,
  components,
  date,
  events,
  onSelectEvent,
}) {
  const renderDay = (day, events, dayKey) => {
    events = events.filter(e =>
      inRange(e, dates.startOf(day, 'day'), dates.endOf(day, 'day'), accessors)
    )

    const momentDay = moment(day)

    if (!momentDay.isValid()) {
      return null
    }

    const monthDay = momentDay.format('dddd, MMMM Do')

    if (events.length === 0) {
      return null
    }

    return (
      <div key={dayKey} className="flex flex-col space-y-4">
        <h3 className="text-base text-white">{monthDay}</h3>
        {events.map((event, idx) => {
          const title = accessors.title(event)

          return (
            <div key={idx} className="flex space-x-2 items-center">
              <span className="text-white opacity-50">
                {timeRangeLabel(day, event)}
              </span>
              <button
                className="flex-grow focus:outline-none text-left text-yellow hover:text-yellow-800 underline"
                onClick={() => onSelectEvent(event)}
              >
                {title}
              </button>
            </div>
          )
        }, [])}
      </div>
    )
  }
  const timeRangeLabel = (day, event) => {
     let label = localizer.messages.allDay

    let end = accessors.end(event)
    let start = accessors.start(event)

    if (!accessors.allDay(event)) {
      if (dates.eq(start, end)) {
        label = localizer.format(start, 'LT')
      } else if (dates.eq(start, end, 'day')) {
        label = localizer.format(start, 'LT')
      } else if (dates.eq(day, start, 'day')) {
        label = localizer.format(start, 'LT')
      } else if (dates.eq(day, end, 'day')) {
        label = localizer.format(end, 'LT')
      }
    }

    return <span>{label}</span>
  }
  const start = dates.startOf(date, 'month')
  const end = dates.endOf(date, 'month')
  const range = dates.range(start, end, 'day')

  events = events.filter(event => inRange(event, start, end, accessors))

  events.sort((a, b) => +accessors.start(a) - +accessors.start(b))

  return (
    <div className="flex flex-col space-y-4">
      {events.length !== 0 ? (
        range.map((day, idx) => renderDay(day, events, idx))
      ) : (
        <h2 className="text-white">No planned events for this month</h2>
      )}
    </div>
  )
}

MonthAgendaView.propTypes = {
  events: PropTypes.array,
  date: PropTypes.instanceOf(Date),
  length: PropTypes.number.isRequired,

  selected: PropTypes.object,

  accessors: PropTypes.object.isRequired,
  components: PropTypes.object.isRequired,
  getters: PropTypes.object.isRequired,
  localizer: PropTypes.object.isRequired,
}

MonthAgendaView.range = date => {
  let start = dates.startOf(date, 'month')
  let end = dates.endOf(date, 'month')

  return { start, end }
}

MonthAgendaView.navigate = (date, action) => {
  switch (action) {
    case 'PREV':
      return dates.add(date, -1, 'month')
    case 'NEXT':
      return dates.add(date, 1, 'month')
    default:
      return date
  }
}

MonthAgendaView.title = (date, { localizer }) =>
  localizer.format(date, 'monthHeaderFormat')

export default MonthAgendaView
